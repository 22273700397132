import axios from 'axios';
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import * as CONSTANTS from '../../CONSTANTS'
import { Button, Card, Divider, Input, message  ,Pagination,Skeleton} from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import 'antd/dist/antd.css'
import { Col, Row } from 'react-bootstrap';
import { Footer } from 'antd/lib/layout/layout';

export const MySales = ({token}) => {
	const tableRef = useRef(null);
	const [key , setKey] = useState(1);

	const {t} = useTranslation('home');
    const [load , setLoading] = useState(true);
    const [appointmentsLoad , setAppointmentsLoading] = useState(false);
    const [total , setTotal] = useState(0);
    const [page , setCurrent] = useState(1);
    const [searchWord , setSearchWord] = useState('');
    const [email , setEmail] = useState('');

	const [plans , setPlans] = useState([]);

    const [appointments , setAppointmentsData] = useState([]);
	const updatePage = title => {
		setLoading(true);


		setAppointmentsLoading(true);
		setLoading(false);
	 }
    const navigate = useNavigate();
    useEffect(() => {
setLoading(true);
        if(token != null ){
            setLoading(true);
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              }
            axios.get(CONSTANTS.DEFAULT_URL+'users/me' , {
                headers: headers
              })
    .then(function (response) {
		let email = response.data.email;
		setEmail(email);
      // handle success
    //   http://3.70.182.78:1337/api/orders?filters[patient][id]=1007
	var query = '';
	setLoading(true);
	if(searchWord === null){
		setSearchWord('');
	}

	// supplier-products?populate[image][fields]=name,url&populate[product_category][fields]=name&filters[supplier][type]=pharmacy&pagination[pageSize]=10&filters[name][$startsWith]=p
	axios.get(CONSTANTS.DEFAULT_URL+`profiles?pagination[page]=${page}&pagination[pageSize]=100&filters[$or][0][$or][0][firstname][$containsi]=${searchWord}&populate[user][role]=*&filters[user][role]=4&filters[user][sales_man_email]=${email}` , {
        headers: headers
      }).then(function (appointmentData){
console.log(appointmentData.data.data);
setTotal(appointmentData.data.meta.pagination.total);
            setAppointmentsData(appointmentData.data.data);

            setAppointmentsLoading(true);
		setLoading(false);
      })


        

    })
    .catch(function (error) {
		console.log(error);
        // navigate('/login');

    setLoading(true);

    if(error.response.status === 403){
        // navigate('/login');


    }
      console.log(error);
    })

}else{
    // navigate('/login');
	console.log('66666');

    setLoading(true);

}

}, [page]);

const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };
  const setSearchName = (word) => {
    console.log(word);
	setSearchWord(word);

  };
  const searchFunction = ()=>{
	setLoading(true);
	setCurrent(1);

	const headers = {
		'Content-Type': 'application/json',
		'Authorization': 'Bearer '+token
	  }
	var query = '';
	if(searchWord){
		query += '&filters[name][$startsWith]='+searchWord;
			}
		
			axios.get(CONSTANTS.DEFAULT_URL+`profiles?pagination[page]=${page}&pagination[pageSize]=100&filters[$or][0][$or][0][firstname][$containsi]=${searchWord}&filters[$or][0][$or][1][lastname][$containsi]=${searchWord}&filters[$or][0][$or][2][user][mobileNumber][$containsi]=${searchWord}&filters[$or][0][$or][3][user][email][$containsi]=${searchWord}&filters[$or][1][$and][0][firstname][$containsi]=${searchWord}&filters[$or][1][$and][1][lastname][$containsi]=&populate[profilePicture]=*&populate[user][role]=*&sort=[user][createdAt]:desc&filters[user][role]=4&filters[user][sales_man_email]=${email}` , {
				headers: headers
			  }).then(function (appointmentData){
		console.log(appointmentData.data.data);
		setTotal(appointmentData.data.meta.pagination.total);
					setAppointmentsData(appointmentData.data.data);
		
					setAppointmentsLoading(true);
				setLoading(false);
			  })
  }

  return (

         <div className='container-fluid'>

  <Suspense>
{load === false ? (<><div class="breadcrumb-bar">
				<div class="container-fluid">
					<div class="row align-items-center">
						<div class="col-md-12 col-12">
							<nav aria-label="breadcrumb" class="page-breadcrumb">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="/">Home</a></li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
            <div class="content">
				<div class="container-fluid">
					<div class="row">
					
						
						<div class="col-md-12">
		

			


				<br/>
				   <br/>
				   <Row>
				 <Col xs={6}>
				 <Input type={'text'} placeholder='Search' value={searchWord} className='form-control' onChange={(e)=>{setSearchName(e.target.value)}} />
				 </Col>
				 <Col md={3}>
				 <Button type='primary' className='btn btn-success' onClick={()=>{searchFunction(1)}}>Search</Button>				 </Col>
				  

				   </Row>
				   <Divider />

					<div  class="tab-pane fade show active">
								<div class="card card-table mb-0">
									<div class="card-body">
										<div class="table-responsive">
											<table class="table table-hover table-center mb-0" ref={tableRef}> 
												<thead>
													<tr>
														<th>{t('Name')}</th>
														<th>{t('Phone')}</th>
														<th>{t('Father Name')}</th>
														<th>{t('Father Phone')}</th>

														<th>{t('Plan From')}</th>
														<th>{t('Plan to')}</th>


														
														<th></th>
													</tr>
												</thead>
												<tbody>
											
				{appointmentsLoad ? (
appointments.length > 0 ? (
<>
	{appointments.map((profile, i) => {
	 
	  return (
	 <><tr>
	  <td>{profile.attributes.firstname+' '+profile.attributes.lastname}</td>

	  <td>{profile.attributes.user.data.attributes.mobileNumber}</td>
	  <td>{profile.attributes.user.data.attributes.father_name}</td>
	  <td>{profile.attributes.user.data.attributes.father_number}</td>

	  <td>{profile.attributes.user.data.attributes.from}</td>
	  <td>{profile.attributes.user.data.attributes.to}</td>


	  </tr>
	 </>)
	}
	)}
	
	<Card><Pagination defaultCurrent={1} current={page} total={total} onChange={onChange} /></Card>
	</>) : (
	  <>No Data</>
	)
  ) : (
	<>
	<Skeleton  avatar
paragraph={{
rows: 4,
}} active />
   </>
  )}
					
									
					</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>


							
						
				
							</div>	
							
					</div>
				</div>

			</div></>) : (<Skeleton active />)}

	  </Suspense>
		</div>

  )
}
